import React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";

import LanguageDetector from 'i18next-browser-languagedetector';


i18n
.use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: "en",
    saveMissing: true,
    debug: false,
    backend: {
        loadPath: '/locales/{{lng}}.json'
      }
  });