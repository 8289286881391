import React from "react";
import { useTranslation } from "react-i18next";

const PersonalInfo: React.FC = () => {

  const { t } = useTranslation("en");

  return <div className="PersonalInfo">
    <p><u>{t('personalInfo')}</u></p>
      <p>{t('linguae')}</p>
      <p>{t('hobbies')}</p>
      <p>{t('email')}</p>
      <p>{t('address')}</p>
    <p>{t('phone')} : <img src="images/belgium.gif" height={20} />+32474907234<br />
    <span className="offseted"><img src="images/canada.gif" height={20} />+1 (437)660-4752</span><br />
    <span className="offseted"><img src="images/uk.gif" height={20} />+447774178415</span></p>
  </div>
};

export default PersonalInfo;
