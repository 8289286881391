import { toTs, tsToShortStr } from "./dateutil";
import { Event, TYPES } from "./types";

export const timelineData: Event[] = [
  {
    label: "Bachelor in Software Engineering",
    startDate: toTs("2009-09-01"),
    endDate: toTs("2012-09-01"),
    type: "EDUCATION",
    line: 0,
    description: "Bachelor's degree in Software Engineering. Mostly focused on software architecture, data structures and algorithms, discrete mathematics, and object-oriented language (Java).",
    place: "HE Léonard de VINCI, Brussels",
    country: "Belgium",
    fullTime: true,
    timePercentage: 100,
    selfEmployed: false,
  },
  {
    label: "Internship",
    shortLabel: "Intern",
    startDate: toTs("2012-02-01"),
    endDate: toTs("2012-05-01"),
    type: "INTERNSHIP",
    line: 1,
    description: "For the last year of my BSC we had to do a 3 months internship, I managed to find a position in England. During the internship I was mostly working on a solution for Blackberry phones using J2ME.",
    place: "Mobile Worker Plus, Richmond",
    country: "UK",
    fullTime: true,
    timePercentage: 100,
    selfEmployed: false,
    hideDate: true
  },
  {
    label: "Software developer at Mobile Worker Plus",
    startDate: toTs("2012-10-01"),
    endDate: toTs("2016-08-30"),
    type: "IT_WORK",
    line: 0,
    description: "After completing an unpaid internship during my studies, they decided to hire me. I quickly became development lead for their back-end and front-end Mobile Worker solution. The technologies used were mostly Java Spring and Java Android.",
    place: "Mobile Worker Plus, Richmond",
    country: "UK",
    fullTime: true,
    timePercentage: 100,
    selfEmployed: false,
  },
  {
    label: "C++ developer at Nobel Biocare",
    startDate: toTs("2016-10-01"),
    endDate: toTs("2018-09-30"),
    type: "IT_WORK",
    line: 0,
    description: "I worked as a C++ developer at Nobel Biocare for 2 years. I joined a team of 50 developers following the Scrum framework by the letter. We were working on 3D desktop software for dental visualization. The technologies used were C++ with Qt.",
    place: "Medicim/Nobel Biocare, Mechelen",
    country: "Belgium",
    fullTime: true,
    timePercentage: 100,
    selfEmployed: false,
  },
  {
    label: "Teacher training",
    startDate: toTs("2018-09-01"),
    endDate: toTs("2019-06-30"),
    type: "EDUCATION",
    hideDate: true,
    line: 2,
    description: "I completed my teacher training at Jury Central, Brussels.",
    place: "Jury Central, Brussels",
    country: "Belgium",
    fullTime: false,
    timePercentage: 20,
    selfEmployed: false,
  },
  {
    label: "CS Teacher",
    startDate: toTs("2018-10-01"),
    endDate: toTs("2019-06-30"),
    type: "SCHOOL",
    line: 0,
    description: "I taught computer science at Institut national de radioélectricité et cinématographie, Brussels. I gave one course on software programming in Python and another course about hardware architecture.",
    place: "Institut national de radioélectricité et cinématographie, Brussels",
    country: "Belgium",
    fullTime: false,
    timePercentage: 66,
    selfEmployed: false,
    hideDate: window.innerWidth < 500
  },
  {
    label: "Remote consultant for Mobile Worker Plus",
    startDate: toTs("2019-01-01"),
    endDate: toTs("2024-06-30"),
    hideDate: true,
    type: "IT_WORK",
    line: 1,
    description: "In 2019, I started working remotely for Mobile Worker Plus for about 10 to 20 hours per week. My duties include mostly maintaining and improving the Mobile Worker solution. The technologies used are React/Typescript for web front-end, Java/Spring for back-end and Java/Android for mobile.",
    place: "Mobile Worker Plus, [remote]",
    country: "UK",
    fullTime: false,
    timePercentage: 40,
    selfEmployed: true,
  },
  {
    label: "Coordinator at CodeNPlay",
    startDate: toTs("2019-10-01"),
    endDate: toTs("2021-08-25"),
    type: "SCHOOL",
    line: 0,
    description: "I worked as a coordinator at CodeNPlay, Brussels. My duties involved facilitating animations about robotics in primary schools, training and supervising new intructors and working on course content.",
    place: "CodeNPlay, Brussels",
    country: "Belgium",
    fullTime: false,
    timePercentage: 50,
    selfEmployed: false,
  },
  {
    label: "Teacher at Saint André",
    startDate: toTs("2021-09-01"),
    endDate: toTs("2023-06-30"),
    type: "SCHOOL",
    line: 0,
    description: "I gave courses on programming to students aged 14 to 17. After one year I became class-referent which meant I was giving individualized academic support to about 25 students.",
    place: "Institut Saint-André Catholic Secondary School, Brussels.",
    country: "Belgium",
    fullTime: false,
    timePercentage: 60,
    selfEmployed: false,
  },
  {
    label: "Teacher at Saint Jean",
    startDate: toTs("2023-09-01"),
    endDate: toTs("2024-06-30"),
    type: "SCHOOL",
    line: 0,
    description: "I currently teach at Saint Jean Catholic school. I am 'enseignant de gestion' which means I teach extra subjects that the main-teachers are not willing to teach. Right now, I'm mostly teaching physical education (which is a very interesting experience for me)",
    place: "Saint Jean French-speaking Catholic School, Ontario, Canada",
    country: "Canada",
    fullTime: false,
    timePercentage: 50,
    selfEmployed: false,
  },
];


export const getFlagPath = (event: Event) : string => {
  if(event.flagFilename && event.flagFilename.length > 0){
    return `images/${event.flagFilename}.gif`;
  }

  if(event.country){
    if(event.country.startsWith("[")){
      return event.country;
    }else{
      return `images/${event.country.toLowerCase()}.gif`;
    }
  }else{
    return ""
  }
}